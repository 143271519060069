import React, { useState, useRef } from "react";
import { Link, Stack, Text, Button } from "basis";

import { logger } from "../../../core";
import { useCustomerAuth } from "../../../core/auth";
import { useThreatMetrix } from "../../../hooks";

import {
  Container,
  ErrorMessage,
  Flex,
  LoadingMessage,
} from "../../../components";
import {
  PaymentMethod,
  Scene,
  AccountErrors,
  completeConsent
} from "../../../layout/wallet";
import { useCustomerOrder } from "../../../layout/checkout";
import { retry } from "../../../utils/retry";
import styled from "styled-components";
import { COUNTRY } from "../../../core/constants";
import { TokenConsentTable } from "../../../layout/merchant";
import { APPLE_CONSENT_DATA } from "../../../layout/wallet/components/constants";
import { RATES } from "../../../components/Promotions/Description/constants";

const Checkout = () => {
  const {
    loading,
    error,
    order,
    setErrorMessage,
    params,
  } = useCustomerOrder();
  const tm = useThreatMetrix();

  const { logout } = useCustomerAuth();
  const [selectedAccount, setSelectedAccount] = useState();
  const [completingPayment, setCompletingPayment] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const portalRef = useRef(null);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const Wrapper = styled.div`
        margin: 24px 0;

        button {
          cursor: pointer;
          width: 100%;
        }
        `;
  const StyledDialog = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow:   
 0 2px 4px rgba(0, 0, 0,   
 0.2);
 overflow-y: scroll;
  max-height: 80vh;
  z-index: 100
`;
  const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index:   
 99; /* Ensure overlay is below the dialog */
`;
  const handlePaymentClick = async () => {
    if (loading || completingPayment) {
      return;
    }

    setCompletingPayment(true);

    try {
      const response = await retry({
        fn: () =>
          completeConsent(
            params.transactionId,
            selectedAccount?.accountID,
            tm.getSessionId()
          ),
        onRetryBegin: (numAttempt) =>
          numAttempt > 0 &&
          logger.info(`handlePaymentClick(): retry #${numAttempt}`, {
            params,
            paymentResponse: {
              selectedAccount,
            },
          }),
        attempts: 10,
        sleepMs: 200,
        sleepMsDelay: [800, 1200, 1400, 1600, 1800, 2000, 2200, 2400, 2600, 2800],
      });
      setCompletingPayment(false);
      window.open(response.redirectUrl, "_self");
    } catch (err) {
      logger.error("handleConsentClick(): failed", { params, err });

      setErrorMessage(
        `We could not update your consent request. Ref # ${params.transactionId}`
      );
    }
  };
  if (loading) {
    return (
      <Scene merchantLogoURL={order?.urlLogo} country={order?.country}>
        <LoadingMessage message="Getting transaction details while you wait .." />
      </Scene>
    );
  }
  if (error?.message) {
    return (
      <Scene merchantLogoURL={order?.urlLogo} country={order?.country}>
        <ErrorMessage
          type={error?.message}
          additionalParams={{
            minimumAmount: order?.minimumAmount,
            currency: order?.currency,
            urlCancel: order?.urlCancel,
            transactionId: order?.transactionId,
          }}
        />
      </Scene>
    );
  }
  return (
    <Scene merchantLogoURL={order?.urlLogo} country={order?.country}>
      <Container margin="4 0" padding="2" maxWidth={1100}>
        <Stack gap="6">
          <Flex>
            <Link href={order?.urlCancel} newTab={false} blue>
              &lt; Back to cart
            </Link>

            <Link href="#" onClick={logout} newTab={false}>
              Log out
            </Link>
          </Flex>

          <Text align="left" textStyle="heading4">
            Confirm Payment Method
          </Text>
        </Stack>
      </Container>


      <Container bg="white" margin="4 0" maxWidth={1100} shadow>
        <Container bg="white" margin="4 0" padding="4 6" maxWidth={600}>


          <PaymentMethod
            loading={completingPayment}
            accounts={order?.accounts}
            onChange={setSelectedAccount}
            label="Using the following account:"
          />
          <AccountErrors
            errors={order?.accountErrors}
            selectedAccount={selectedAccount}
          />
          <Wrapper>
            <Text textStyle="legal" color="grey.t75" margin="8 0 4">By clicking ‘Use Payment Method’ you agree that:</Text>
            <ol style={{ color: '#414141', fontFamily: "Roboto, sans-serif", fontWeight: "300", fontSize: "14px", lineHeight: "24px", marginBottom: '16px', paddingLeft: '14px' }}>
              <li style={{ marginBottom: '16px' }}>

                Purchases made in connection to your Officeworks Upgrade+ plan will be processed using the card ending in {selectedAccount?.cardLast4} .Detailed information on how your Officeworks Upgrade+ plan and selected products will be processed onto Latitude Interest Free payment plans is viewable in the table below and in the Officeworks Upgrade+ cart.

              </li>
              <li style={{ marginBottom: '16px' }}>
                You have read, understand
                and accept our{" "}
                <Link
                  href={`https://www.latitudefinancial.com.au/terms-and-conditions-library/?gemid2=lfs-web%3Acards%3Acredit-cards%3Acredit-card-terms-conditions-library%3Afooter`}
                  newTab
                >
                  terms and conditions
                </Link>{" "}
                and{" "}
                <Link
                  href={
                    order?.country === COUNTRY.NZ
                      ? `https://www.gemfinance.co.nz/privacy`
                      : `https://www.latitudefinancial.com.au/privacy`
                  }
                  newTab
                >
                  privacy policy
                </Link>
                .

              </li>
            </ol>
            <Button
              variant="primary"
              showButton={Boolean(selectedAccount?.isAvailable)}
              loading={completingPayment}
              onClick={handlePaymentClick}
            >
              Use Payment Method
            </Button>
          </Wrapper>

        </Container>
        <Container margin="4 0" padding="2" maxWidth={900}>
          <Text margin="0 0 4" textStyle="heading6">Please see how your Officeworks Upgrade+ purchase will be processed based off your plan and product selection.</Text>
          <Stack gap="4">
            <TokenConsentTable data={APPLE_CONSENT_DATA} tableheader={(data) => <Text><strong>{data}</strong></Text>} cellValue={(data) => <Text>{data}</Text>} />
            <div>
              <Flex justify="center" alignContent="center">
                <Button onClick={handleOpen} variant="secondary" margin="0 4 0 2">
                  See terms and conditions
                </Button>
              </Flex>
              {isOpen && (
                <>
                  <StyledOverlay />
                  <StyledDialog>
                    <Text textStyle="legal" margin="0 0 4" >*Latitude: Approved customers only. Interest Free plans available on participating Latitude credit cards. Latitude T&Cs, card fees & other charges apply.</Text>
                    <Text textStyle="legal" margin="0 0 4">`Keep-The-Device payment is a balloon payment, processed separately on another Latitude Interest Free Payment Deferred Plan and payable if you don’t upgrade or trade-in and end your plan at the end of your plan term, or if your device does not pass assessment by trade in partner Moorup. If you fail to make your required payment, your account will be overdue, interest will be charged at the Expired Promotional Purchase Rate (currently {RATES.GEM_VISA_AU}%, subject to change), late fees will apply and interest applies to late fees and if you do not comply with the T&Cs (see your card T&Cs).`
                    </Text >
                    <Text textStyle="legal" margin="0 0 4">
                      `For Premium Plans: Apple One ($31.95/month) is processed separately on another 1 Month Interest Free Plan with equal payment, which will reoccur monthly for the duration of your Premium Plan device term. If you fail to make your required monthly payment, your account will be overdue, interest will be charged at the Expired Promotional Purchase Rate (currently {RATES.GEM_VISA_AU}%, subject to change), late fees will apply and interest applies to late fees and if you do not comply with the T&Cs (see your card T&Cs). In addition, your Apple One Service Plan will be suspended until payment is received. You can only cancel your Apple One Service Plan if you return your Officeworks Upgrade+ device under Officeworks’ returns policy or if, when eligible, you trade-in and end your plan.`</Text>
                    <Text textStyle="legal" margin="0 0 4">`For 12 & 24 month Interest Free plans, equal monthly payments are required. If you fail to make your required monthly payment for two consecutive months, your equal monthly payment plan will change into a minimum monthly payment plan (Interest Free Promotion) for the remaining duration of the initial plan term (separate T&Cs apply). If you fail to make your required minimum monthly payment amounts, your account will be overdue, late fees will apply and interest applies to late fees and if you do not comply with the T&Cs (see your card T&Cs). Defaulting on your plan can result in cancelation of your Interest Free plan. If there is an outstanding balance after the interest free period ends, interest will be charged in accordance with the card T&Cs at the Expired Promotional Purchase Rate, currently {RATES.GEM_VISA_AU}% (subject to change).`</Text>
                    <Text textStyle="legal" margin="0 0 4">`For 36 month Interest Free plans, equal monthly payments are required. If you fail to make your required monthly payments, your account will be overdue, late fees will apply and interest applies to late fees and if you do not comply with the T&Cs (see your card T&Cs). Defaulting on your plan can also result in cancelation of your Interest Free plan. If there is an outstanding balance after the interest free period ends, interest will be charged in accordance with the card T&Cs at the Expired Promotional Purchase Rate, currently {RATES.GEM_VISA_AU}% (subject to change).
                      `</Text>
                    <Text textStyle="legal" margin="0 0 4">Credit provided by Latitude Finance Australia ABN 42 008 583 588 Australian Credit Licence number 392145.</Text>
                    <Flex justify="center" alignContent="center">
                      <Button onClick={handleClose} variant="secondary" margin="0 0 0 2">
                        Close
                      </Button>
                    </Flex>
                  </StyledDialog>
                </>
              )}
            </div>
          </Stack>
        </Container>
      </Container>
    </Scene>
  );
};

export default Checkout;
